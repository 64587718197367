import React from 'react';
import { Document } from '../../models';
import Modal from '../common/Modal';
import DocumentView from '../document/DocumentView';

interface DocumentPreviewModalProps {
  modalOpen: boolean;
  closeModal: () => void;
  document: Document;
}

export default function DocumentPreviewModal({
  modalOpen,
  closeModal,
  document,
}: DocumentPreviewModalProps) {
  return (
    <Modal
      modalOpen={modalOpen}
      closeModal={closeModal}
      className="max-w-2xl h-2/3 sm:h-5/6 gap-2 px-4 py-8 sm:p-8"
    >
      <h3 className="text-sm sm:text-base md:text-xl font-semibold text-gray-900 text-center">
        {document?.name}
      </h3>
      <div className="flex grow overflow-y-auto">
        <DocumentView template={document?.template} isModal />
      </div>
    </Modal>
  );
}
