import React from 'react';
import {
  InstantSearch,
  Configure,
  ConfigureProps,
} from 'react-instantsearch-hooks-web';
import { useAlgolia, useCountry } from '../../hooks';
import AlgoliaSearch from '../algolia/AlgoliaSearch';
import Modal from '../common/Modal';
import DocumentSearchHits from './DocumentSearchHits';

interface DocumentSearchModalProps {
  modalOpen: boolean;
  closeModal: () => void;
}

export default function DocumentSearchModal({
  modalOpen,
  closeModal,
}: DocumentSearchModalProps) {
  const searchClient = useAlgolia();
  const { currentCountry } = useCountry();
  const [inputEmpty, setInputEmpty] = React.useState(true);

  const configureProps: ConfigureProps = {
    ruleContexts: [],
    facetFilters: [[`docType:client`], [`country._id:${currentCountry._id}`]],
  };

  return (
    <Modal
      modalOpen={modalOpen}
      closeModal={closeModal}
      className="w-full md:max-w-2xl max-h-[500px] p-5 gap-3"
    >
      <InstantSearch
        searchClient={searchClient}
        indexName="documents"
        routing
        insights
      >
        <Configure {...configureProps} />
        <AlgoliaSearch inputEmpty={inputEmpty} setInputEmpty={setInputEmpty} />
        <div className="w-full grow overflow-y-auto">
          <DocumentSearchHits inputEmpty={inputEmpty} />
        </div>
      </InstantSearch>
    </Modal>
  );
}
