import React from 'react';
import { Document } from '../models';

export default function useExchangeRate(document: Document) {
  const rate = React.useMemo(
    () => document?.user?.lawyer?.firm?.country?.currency?.rate ?? 1,
    [document]
  );

  const symbol = React.useMemo(
    () => document?.user?.lawyer?.firm?.country?.currency?.symbol ?? 'USD',
    [document]
  );

  const exchangePrice = (price?: number) =>
    ((price ?? 0) * (rate ?? 1)).toLocaleString(
      document?.user?.lawyer?.firm?.country?.name === 'México'
        ? 'en-US'
        : 'es-ES',
      {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }
    );

  return { rate, symbol, exchangePrice };
}
