import React from 'react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { cn } from '../../utils/cn';

interface SearchBarProps {
  ref?: React.LegacyRef<HTMLInputElement>;
  autoComplete?: string;
  autoCorrect?: string;
  autoCapitalize?: string;
  placeholder?: string;
  spellCheck?: boolean;
  maxLength?: number;
  autoFocus?: boolean;
  value?: string;
  className?: string;
  onClick?: () => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function SearchBar({
  placeholder = 'Buscar',
  onClick,
  onChange,
  className,
  ...props
}: SearchBarProps) {
  const [focus, setFocus] = React.useState(false);
  return (
    <div
      onClick={onClick}
      className={cn(
        'flex h-fit w-full max-w-[400px] items-center justify-start gap-2 rounded-lg border border-gray-300 bg-white px-3.5 py-2.5 text-gray-500 shadow',
        focus && 'border-primary-600 ring-1 ring-primary-600',
        className
      )}
    >
      <MagnifyingGlassIcon className="h-5 w-5" />
      <input
        type="text"
        className="w-full border-none p-0 focus:outline-none text-gray-900"
        placeholder={placeholder}
        onChange={onChange}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        {...props}
      />
    </div>
  );
}
