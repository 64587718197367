import React from 'react';
import useWindowSize from '../../hooks/useWindowSize';
import DocumentCard from './DocumentCard';
import { Document } from '../../models';

interface HomeDocumentsProps {
  documents?: Document[];
  noResults?: boolean;
}

export default function HomeDocuments({
  noResults,
  documents,
}: HomeDocumentsProps) {
  const { width } = useWindowSize();

  return (
    <div className="flex w-full flex-col justify-start my-[2px] px-[2px]">
      {/* Display no results message if there are no documents */}
      {noResults ? (
        <p className="mb-10 mt-5 flex w-full items-center justify-center px-10">
          No se han encontrado resultados para tu búsqueda. Por favor, intenta
          de nuevo o comunícate con nosotros a través de nuestra se sección de
          contacto.
        </p>
      ) : (
        <div
          className={`grid w-full place-items-center items-center justify-center gap-5 ${
            width < 1072
              ? 'grid-cols-1'
              : width > 1072 && width < 1400
              ? 'grid-cols-2 '
              : 'grid-cols-3 '
          } `}
        >
          {documents?.map((document) => (
            <DocumentCard key={document._id} {...document} />
          ))}
        </div>
      )}
    </div>
  );
}
