/* eslint-disable @next/next/no-img-element */
import React from 'react';
import parse from 'html-react-parser';
import { cn } from '../../utils/cn';

interface DocumentViewProps {
  template: string;
  isModal?: boolean;
}

export default function DocumentView({ template, isModal }: DocumentViewProps) {
  const templateMirror = (htmlString: string) => {
    // Expresión regular para encontrar los valores entre símbolos de porcentaje (%) sin espacios entre ellos
    const regexVariable = /%([^% ]+)%/g;
    const signVariable = /\{\{(.*?)\}\}/g;

    // Reemplazar todos los valores en el string HTML
    return htmlString
      .replace(regexVariable, '____________')
      .replace(signVariable, '____________________');
  };

  return (
    <div
      className={cn(
        'relative w-full cursor-not-allowed bg-white p-6',
        isModal
          ? 'min-w-0 max-w-lg md:max-h-fit p-0 h-full'
          : 'h-[600px] md:h-[700px] lg:min-w-[580px] lg:max-w-[670px]'
      )}
    >
      <div
        className={cn(
          'h-full select-none px-5 py-6 text-xs md:px-10 md:py-12 md:text-sm overflow-y-scroll',
          isModal && 'border border-gray-200 shadow md:text-xs'
        )}
      >
        {parse(templateMirror(template))}
      </div>
      <img
        alt="logo"
        src="/logos/widu-icon.png"
        className="absolute left-1/2 top-1/2 -translate-y-1/2 w-1/2 -translate-x-1/2 transform opacity-30"
      />
    </div>
  );
}
