import React from 'react';

interface SectionProps {
  title: string;
  description: string;
  trailing?: React.ReactNode;
  children: React.ReactNode;
}

export default function Section({
  title,
  description,
  trailing,
  children,
}: SectionProps) {
  return (
    <article className="flex h-full w-full flex-col bg-gray-50 px-8 py-2 lg:px-20">
      <div className="flex w-full flex-col items-center justify-between gap-8 border-b border-gray-200 py-6 md:flex-row">
        <div className="flex w-full flex-col items-center justify-center gap-1 shrink-0 md:w-1/2 md:items-start">
          <h2 className="text-2xl font-semibold text-gray-900 md:text-3xl">
            {title}
          </h2>
          <p className="text-sm text-gray-600">{description}</p>
        </div>
        {trailing}
      </div>
      <section className="h-full py-8">{children}</section>
    </article>
  );
}
