import React from 'react';

interface SelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  additionalClasses?: string;
}

export default function Select({
  name,
  id,
  onChange,
  required,
  children,
  additionalClasses = '',
}: SelectProps) {
  return (
    <select
      name={name}
      id={id}
      onChange={onChange}
      required={required}
      className={`flex w-full items-center justify-start rounded-lg border border-gray-300 bg-white px-3.5 py-2.5 text-gray-900 shadow focus:border-transparent focus:outline-none focus:ring-2 focus:ring-primary-500 ${additionalClasses}`}
    >
      {children}
    </select>
  );
}
