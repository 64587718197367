import React from 'react';
import { cn } from '../../utils/cn';

interface BadgeProps {
  text?: string;
  className?: string;
}

export default function Badge({ text, className }: BadgeProps) {
  return (
    <span
      className={cn(
        'flex w-fit justify-center rounded-lg border border-primary-200 bg-primary-25 px-2 py-[2px] text-xs font-medium text-primary-700',
        className
      )}
    >
      {text}
    </span>
  );
}
