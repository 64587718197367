/* eslint-disable no-nested-ternary */
import React from 'react';
import { useRouter } from 'next/router';
import Badge from '../common/Badge';

export default function DocumentSearchItem(document) {
  const router = useRouter();

  return (
    <li
      onClick={() => router.push(`document/${document?.slug}`)}
      className="relative bg-gray-50 border border-gray-300 text-gray-900 rounded-lg flex px-4 py-2 hover:bg-primary-50 hover:border-primary-400 hover:cursor-pointer"
    >
      <div className="flex flex-col w-full gap-2">
        <div className="w-full flex justify-between gap-5">
          <span className="text-sm font-medium line-clamp-2">
            {document?.name}
          </span>
          <Badge text={document?.category.name} className="h-fit" />
        </div>
        {document?.description && (
          <p
            dangerouslySetInnerHTML={{ __html: document?.description }}
            className="line-clamp-1 text-xs text-gray-600"
          />
        )}
      </div>
    </li>
  );
}
