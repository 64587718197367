import React from 'react';
import { useInstantSearch } from 'react-instantsearch-hooks-web';
import DocumentSearchItem from './DocumentSearchItem';

interface DocumentSearcHits {
  inputEmpty: boolean;
}

export default function DocumentSearchHits({ inputEmpty }: DocumentSearcHits) {
  const { results } = useInstantSearch();

  return (
    <div className="w-full flex overflow-y-scroll items-center justify-center">
      {inputEmpty || results?.hits?.length === 0 ? (
        <div className="h-20 flex justify-center items-center">
          <p className="">No hay resultados</p>
        </div>
      ) : (
        <ul className="w-full flex flex-col justify-start gap-1">
          {results?.hits?.map((hit) => (
            <DocumentSearchItem key={hit} {...hit} />
          ))}
        </ul>
      )}
    </div>
  );
}
