/* eslint-disable no-nested-ternary */
import React from 'react';
import { useRouter } from 'next/router';
import { Document } from '../../models';
import PrimaryButton from '../common/PrimaryButton';
import SecondaryButton from '../common/SecondaryButton';
import Badge from '../common/Badge';
import DocumentPreviewModal from '../modals/DocumentPreviewModal';
import useExchangeRate from '../../hooks/useExchangeRate';

export default function DocumentCard(document: Document) {
  const router = useRouter();
  const { exchangePrice, symbol } = useExchangeRate(document);
  const [isOpenPreviewDocumentModal, setOpenPreviewDocumentModal] =
    React.useState<boolean>(false);

  const randomThumbnail = React.useMemo(
    () => Math.floor(Math.random() * 6) + 1,
    []
  );
  return (
    <div className="group relative w-full max-w-sm md:max-w-none rounded-xl border border-gray-200 bg-white p-5 hover:ring-2 hover:ring-primary-600 md:h-[404px] md:w-[280px]">
      {/* Document Preview */}
      <div className="hidden h-full items-start justify-center bg-gray-50 p-2 md:flex">
        <img
          src={`/thumbnails/thumbnail${randomThumbnail}.png`}
          alt="Preview de documento"
          className="blur-[0.8px]"
        />
      </div>

      {/* Document Category */}
      <Badge
        text={document.category?.name}
        className="absolute left-3 top-3 hidden md:flex"
      />

      {/* Document Data */}
      <div className="absolute bottom-0 left-0 hidden w-full flex-col gap-3 rounded-b-xl bg-white p-5 group-hover:shadow md:flex">
        <div className="flex flex-col gap-2">
          <div className="line-clamp-3 font-semibold text-gray-900">
            {document.name}
          </div>
          <p
            dangerouslySetInnerHTML={{ __html: document.description }}
            className="line-clamp-2 text-sm text-gray-600"
          />
        </div>
        <div className="hidden flex-col gap-3 group-hover:flex">
          <PrimaryButton
            onClick={() =>
              router?.push(
                '/document/[documentSlug]',
                `/document/${document?.slug}`
              )
            }
          >
            Solicitar {symbol}
            {exchangePrice(document.price)}
          </PrimaryButton>
          <SecondaryButton
            onClick={(e) => {
              e.preventDefault();
              setOpenPreviewDocumentModal(true);
            }}
          >
            Vista previa
          </SecondaryButton>
        </div>
      </div>

      {/* < display-md */}
      <div className="flex w-full flex-col gap-4 md:hidden">
        <div className="flex items-start justify-between gap-5">
          <div className="line-clamp-3 font-semibold text-gray-900">
            {document.name}
          </div>
          <Badge text={document.category?.name} className="" />
        </div>
        <p
          dangerouslySetInnerHTML={{ __html: document.description }}
          className="line-clamp-3 text-sm text-gray-600"
        />
        <div className="flex flex-col gap-3">
          <PrimaryButton
            onClick={() =>
              router?.push(
                '/document/[documentSlug]',
                `/document/${document?.slug}`
              )
            }
          >
            Solicitar {symbol}
            {exchangePrice(document.price)}
          </PrimaryButton>
          <SecondaryButton
            onClick={(e) => {
              e.preventDefault();
              setOpenPreviewDocumentModal(true);
            }}
          >
            Vista previa
          </SecondaryButton>
        </div>
      </div>

      {/* Preview Document Modal */}
      <DocumentPreviewModal
        modalOpen={isOpenPreviewDocumentModal}
        closeModal={() => setOpenPreviewDocumentModal(false)}
        document={document}
      />
    </div>
  );
}
