import React from 'react';
import { useRouter } from 'next/router';
import { FilteredCategories } from '../../models';
import CategoryTab from '../common/CategoryTab';
import Select from '../common/Select';

interface DocumentCategoriesProps {
  categories: FilteredCategories[];
}

export default function DocumentCategories({
  categories,
}: DocumentCategoriesProps) {
  const router = useRouter();
  const { category: selectedCategory } = router.query;

  const allCategoriesCount = React.useMemo(
    () => categories.reduce((acc, category) => acc + category.count, 0),
    [categories]
  );

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === 'all') return router.push('/');
    else return router.push(`/?category=${e.target.value}`);
  };

  return (
    <>
      {/* >= display-md */}
      <div className="hidden w-full rounded-xl border border-gray-200 bg-white p-[6px] md:flex mt-[2px]">
        <ul className="flex flex-col gap-1">
          <CategoryTab
            key="all"
            name="Todos"
            quantity={allCategoriesCount}
            onClick={() => router.push('/')}
            active={selectedCategory === undefined}
          />
          {categories
            .filter((category) => category.count > 0)
            .map((category) => (
              <CategoryTab
                key={category.category._id}
                name={category.category.name}
                quantity={category.count}
                onClick={() =>
                  router.push(`/?category=${category.category._id}`)
                }
                active={selectedCategory === category.category._id}
              />
            ))}
        </ul>
      </div>

      {/* display-sm */}
      <Select
        name="category"
        additionalClasses="max-w-sm md:hidden"
        id="category"
        onChange={(e) => handleChange(e)}
      >
        <option value="all">Todos</option>
        {categories.map((category) => (
          <option key={category.category._id} value={category.category._id}>
            {category.category.name}
          </option>
        ))}
      </Select>
    </>
  );
}
