import React from 'react';
import { UseSearchBoxProps, useSearchBox } from 'react-instantsearch-hooks-web';
import SearchBar from '../common/SearchBar';

interface AlgoliaSearchProps {
  inputEmpty: boolean;
  setInputEmpty: React.Dispatch<React.SetStateAction<boolean>>;
  searchBoxProps?: UseSearchBoxProps; // Renamed to searchBoxProps for clarity
}

const AlgoliaSearch: React.FC<AlgoliaSearchProps> = ({
  inputEmpty,
  setInputEmpty,
  searchBoxProps, // Updated to searchBoxProps
}) => {
  const { query, refine } = useSearchBox(searchBoxProps);

  React.useEffect(() => {
    if (inputEmpty && query === '') return;
    if (!inputEmpty && query === '') {
      setInputEmpty(true);
    } else if (inputEmpty && query !== '') {
      setInputEmpty(false);
    }
  }, [inputEmpty, query, setInputEmpty]);

  return (
    <SearchBar
      autoComplete="on"
      autoCorrect="on"
      placeholder="Buscar documento" // Placeholder text for the input field
      value={query} // The current search query value
      autoFocus
      onChange={(event) => {
        event.preventDefault();
        refine(event.currentTarget.value);
      }}
      className="w-full max-w-full focus:border focus:border-primary-500"
    />
  );
};

export default AlgoliaSearch;
