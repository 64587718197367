import React from 'react';
import { cn } from '../../utils/cn';

interface CategoryTabProps {
  active?: boolean;
  name?: string;
  quantity?: number;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export default function CategoryTab({
  active,
  name,
  quantity,
  onClick,
}: CategoryTabProps) {
  return (
    <li>
      <button
        type="button"
        onClick={onClick}
        className={cn(
          'flex w-full items-center gap-2 rounded-lg px-3 py-2 text-start text-sm font-semibold transition-colors duration-200 ease-in-out',
          active
            ? 'bg-primary-50 text-primary-700 shadow hover:bg-primary-100 hover:text-primary-800'
            : 'text-gray-500 hover:bg-gray-100 hover:text-gray-700'
        )}
      >
        {name}
        <span
          className={cn(
            'flex w-8 items-center justify-center rounded-full border px-3 py-1 text-xs',
            active
              ? 'border-primary-200 text-primary-700'
              : 'border-gray-200 bg-gray-50'
          )}
        >
          {quantity ?? 0}
        </span>
      </button>
    </li>
  );
}
