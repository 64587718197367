/* eslint-disable no-nested-ternary */
import React from 'react';
import Head from 'next/head';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useQuery } from '@apollo/client';
import { TailSpin } from 'react-loader-spinner';
import { useRouter } from 'next/router';
import {
  GET_DOCUMENTS_PAGINATION,
  GET_FILTERED_CATEGORIES,
} from '../graphql/queries';
import { Document, FilteredCategories, PaginationInfo } from '../models';
import { useCountry } from '../hooks/useCountry';
import AppointmentModal from '../components/appointments/AppointmentModal';
import MainLayout from '../components/layouts/MainLayout';
import DocumentCategories from '../components/document/DocumentCategories';
import Section from '../components/common/Section';
import HomeDocuments from '../components/document/HomeDocuments';
import DocumentSearchModal from '../components/document/DocumentSearchModal';
import SearchBar from '../components/common/SearchBar';

function DocumentsPage() {
  const router = useRouter();
  const { category: selectedCategory } = router.query;
  const { currentCountry } = useCountry();
  const [documents, setDocuments] = React.useState<Document[]>([]);
  const [hasNextPage, setHasNextPage] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState<number>(2);

  const [searchModalOpen, setSearchModalOpen] = React.useState(false);

  // State variables for handling appointment modal and the selected appointment document
  const [openAppointmentModal, setOpenAppointmentModal] = React.useState(false);
  const [appointmentDocument, setAppointmentDocument] =
    React.useState<string>('');

  // Query for fetching documents with pagination
  const { loading: loadingDocuments, fetchMore: fetchMoreDocuments } =
    useQuery<{
      getDocuments: {
        items: Array<Document>;
        count: number;
        pageInfo: PaginationInfo;
      };
    }>(GET_DOCUMENTS_PAGINATION, {
      fetchPolicy: 'network-only',
      variables: {
        data: {
          page: 1,
          perPage: 6,
          country: currentCountry?._id,
          category: selectedCategory,
        },
      },
      onCompleted: (data) => {
        setCurrentPage(1);
        setDocuments(data?.getDocuments?.items);
        setHasNextPage(data?.getDocuments?.pageInfo?.hasNextPage);
      },
      onError: (error) =>
        console.log(
          'Error refetching documents by country and category',
          error
        ),
    });

  // Query for fetching filtered categories
  const { data: filteredCategoriesData, loading: filteredCategoriesLoading } =
    useQuery<{
      getFilteredCategories: {
        categories: FilteredCategories[];
      };
    }>(GET_FILTERED_CATEGORIES, {
      variables: {
        data: {
          country: currentCountry?._id,
        },
      },
    });

  // Fetch more documents on scroll
  const infiniteScrollFetchMore = async () => {
    const { data: moreDocuments } = await fetchMoreDocuments({
      variables: {
        data: {
          page: currentPage + 1,
          perPage: 6,
          country: currentCountry?._id,
          category: selectedCategory,
        },
      },
    });

    setCurrentPage((prev) => prev + 1);
    setDocuments([...documents, ...(moreDocuments?.getDocuments?.items ?? [])]);
    setHasNextPage(moreDocuments?.getDocuments?.pageInfo?.hasNextPage);
  };

  return (
    <MainLayout>
      <Head>
        <title>Documentos disponibles | Widú Legal</title>
        <meta
          name="description"
          content="Documentos disponibles: En WIDÚ LEGAL podrás encontrar documentos legales en línea, válidos y confiables en toda Latinoamérica ¡Solicita tu asesoría hoy!"
        />
        <meta
          property="og:title"
          content="Documentos disponibles | Widú Legal"
        />
        <meta
          property="og:description"
          content="Documentos disponibles: En WIDÚ LEGAL podrás encontrar documentos legales en línea, válidos y confiables en toda Latinoamérica ¡Solicita tu asesoría hoy!"
        />
      </Head>
      <Section
        title="Plantillas"
        description="Selecciona una plantilla lista para usar"
        trailing={<SearchBar onClick={() => setSearchModalOpen(true)} />}
      >
        {!filteredCategoriesLoading ? (
          <div className="flex h-full w-full flex-col gap-10 md:flex-row">
            <div className="flex h-fit justify-center">
              <DocumentCategories
                categories={
                  filteredCategoriesData.getFilteredCategories.categories ?? []
                }
              />
            </div>
            <div className="w-full md:w-5/6">
              <InfiniteScroll
                dataLength={documents?.length}
                next={infiniteScrollFetchMore}
                className="flex h-full w-full flex-col items-center"
                hasMore={hasNextPage}
                loader={
                  <div className="z-10 mt-5 flex w-full flex-row justify-center">
                    <TailSpin
                      height={80}
                      width={80}
                      color="#214497"
                      ariaLabel="loading"
                    />
                  </div>
                }
                height={700}
              >
                {/* If no documents match the search/filter criteria, display a message */}
                {loadingDocuments ? (
                  <div className="flex h-96 w-full items-center justify-center">
                    <TailSpin
                      height={80}
                      width={80}
                      color="#214497"
                      ariaLabel="loading"
                    />
                  </div>
                ) : (
                  <HomeDocuments
                    documents={documents}
                    noResults={documents?.length === 0}
                  />
                )}

                {/* Show the AppointmentModal for scheduling a document request */}
                <AppointmentModal
                  isOpen={openAppointmentModal}
                  setOpen={setOpenAppointmentModal}
                  hasDocuments
                  appointmentDocument={appointmentDocument}
                  setAppointmentDocument={setAppointmentDocument}
                  documentData={documents}
                />
              </InfiniteScroll>
            </div>
          </div>
        ) : (
          <div className="z-10 mt-5 flex h-full items-center justify-center">
            <TailSpin
              height={80}
              width={80}
              color="#214497"
              ariaLabel="loading"
            />
          </div>
        )}
      </Section>
      <DocumentSearchModal
        modalOpen={searchModalOpen}
        closeModal={() => setSearchModalOpen(false)}
      />
    </MainLayout>
  );
}

export default DocumentsPage;
